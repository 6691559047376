<template>
  <div class="collection-detail-wrapper">
    <div class="content-title-area">
      <p class="title">
        集采认购编号： {{ detailsObj.subscribeNo }}<Status :status="Number(detailsObj.subscribeStatus)" :status-list="collectionSubscribeStatus" :status-map="subscribeStatus" />
      </p>
      <!-- <p>距离集采终止 <span>9 天 0 时 54 分 55 秒</span></p> -->
    </div>
    <!-- <div>
            <div class="content-title-area">
                <p class="title">集采项目统计</p>
                <el-button type="primary" size="small" @click="gotoCustomerDetail">查看统计明细</el-button>
            </div>
            <div class="statistics">
                <div v-for="(item, index) in projectStatistics" :key="index" class="statistics-item">
                    <p>{{item.value}}</p>
                    <p>{{item.label}}</p>
                </div>
            </div>
        </div> -->
    <div class="collection-detail">
      <Details-page
        :details-title="'集采信息'"
        :detail-obj="detailsObj"
        :details-item-arr="detailsItemArr"
        :item-data="itemData"
        :list-data="listData"
      />
    </div>
    <div class="subscribe-step-wrapper">
      <div class="content-title-area">
        <p class="title">
          认购流程
        </p>
      </div>
      <div class="subscribe-step">
        <div class="subscribe-step-item">
          <div class="subscribe-step-top">
            <img src="@/assets/image/registerForSubs.png" alt="">
            <img v-if="detailsObj.subscribeStatus > 1" src="@/assets/image/stepActive.png" alt="">
          </div>
          <div class="subscribe-content">
            <p class="subscribe-step-title">
              报名认购
            </p>
            <p><span>报名时间</span><span>{{ detailsObj.enterTime }}</span></p>
            <p><span>认购数量</span><span>{{ detailsObj.enterQuantity }}{{ $codeNew(meterageUnitOptions, detailsObj.meterageUnit) }}</span></p>
            <p><span>认购人</span><span>{{ detailsObj.subscribeMan }}</span></p>
            <p><span>支付账户</span><span>{{ $codeNew(settleBankName, detailsObj.settleBankId) }}</span></p>
          </div>
        </div>
        <div v-if="detailsObj.subscribeStatus >= 2" class="subscribe-step-item">
          <div class="subscribe-step-top">
            <img src="@/assets/image/subscribe.png" alt="">
            <img v-if="detailsObj.subscribeStatus >= 7" src="@/assets/image/stepActive.png" alt="">
          </div>
          <div class="subscribe-content">
            <p class="subscribe-step-title">
              缴纳金额
            </p>
            <p><span>缴纳时间(最新)</span><span>{{ detailsObj.lastPayTime }}</span></p>
            <p><span>累计支付金额</span><span class="amount">{{ detailsObj.countPayAmount||0 }}元</span></p>
            <p><span>缴费人(最新)</span><span>{{ detailsObj.lastPayUser }}</span></p>
          </div>
        </div>
        <div v-if="detailsObj.subscribeStatus === 7" class="subscribe-step-item">
          <div class="subscribe-step-top">
            <img src="@/assets/image/hasOrdered.png" alt="">
          </div>
          <div class="subscribe-content">
            <p class="subscribe-step-title">
              已成单
            </p>
            <p><span>成单时间</span><span>{{ detailsObj.completeOrCancelOrderTime }}</span></p>
            <p><span>成单数量</span><span>{{ detailsObj.completeOrderTotalQuantity }}{{ $codeNew(meterageUnitOptions, detailsObj.meterageUnit) }}</span></p>
            <p><span>成单单价</span><span>{{ detailsObj.completeOrderPrice || 0 }}元</span></p>
            <p><span>关联订单号</span><span class="order">{{ detailsObj.relationOrderNo }}</span></p>
          </div>
        </div>
        <div v-if="detailsObj.subscribeStatus === 8" class="subscribe-step-item">
          <div class="subscribe-step-top">
            <img src="@/assets/image/canceled.png" width="96" height="96" alt="">
          </div>
          <div class="subscribe-content">
            <p class="subscribe-step-title">
              已取消
            </p>
            <p><span>取消时间</span><span>{{ detailsObj.completeOrCancelOrderTime }}</span></p>
            <p><span>取消原因</span><span>{{ $codeNew(cancelReason, detailsObj.cancelReason + '') }}</span></p>
            <p><span>取消备注</span><span>如有已缴纳的金额，取消时作退回</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSelectAreaTree } from '@/api/login'
import { getSubscribeDetail } from '@/api/supplyChain'
import { subscribeStatus } from '../menu'
import Status from '../components/collectionStatus.vue'
import DetailsPage from '@/components/DetailsPage.vue'
import { thousandsFractionsSmaller } from '@/utils/util'
export default {
  components: { DetailsPage, Status },
  data() {
    return {
      cancelReason: this.$store.getters.getDictionaryItem('CANCAL_REASON'),
      meterageUnitOptions: this.$store.getters.getDictionaryItem('purchaseUnit'),
      settleBankName: this.$store.getters.getDictionaryItem('settleBankName'),
      subscribeStatus,
      collectionSubscribeStatus: this.$store.getters.getDictionaryItem('SUBSCRIBESTATUS'),
      detailsObj: {},
      listData: [],
      detailsItemArr: [
        { label: '集采项目名称', value: 'projectName' },
        { label: '创建时间', value: 'createTime' },
        { label: '报名截止时间', value: 'enterEndDate' },
        { label: '尾款截止时间', value: 'balancePaymentEndDate' },
        { label: '集采终止时间', value: 'projectEndDate' },
        { label: '保证金', value: 'depositValue' },
        { label: '交收方式', value: 'settlementStyle', child: [{ val: '过户', id: 1 }] },
        { label: '提货仓库', value: 'deliveryWarehouseName' },
        { label: '交货期限', value: 'deliveryPeriod' },
        { label: '联系人', value: 'linkMan' },
        { label: '联系电话', value: 'linkPhone' },
        { label: '微信咨询', value: 'wechatNumber' },
        { label: '交货地址', value: 'address', span: 2 }
      ],
      itemData: [{ label: '品名', prop: 'threeName', width: 200 },
        { label: '材质', prop: 'goodsMaterials', width: 180 },
        { label: '规格', prop: 'goodsSpecs', width: 220 },
        { label: '产地', prop: 'goodsProduct', width: 180 },
        { label: '件重', prop: 'goodsWeights', width: 180 },
        { label: '预计集采总量', prop: 'centralizedPurchaseTotalQuantity', width: 140 },
        { label: '最小认购量', prop: 'minSubscribeQuantity', width: 140 },
        { label: '计量单位', prop: 'meterageUnit', width: 160, child: this.$store.getters.getDictionaryItem('purchaseUnit') },
        { label: '集采单价', prop: 'centralizedPurchasePrice', width: 180 },
        { label: '当前市场价', prop: 'currentMarketPrice', width: 140 }
      ]
    }
  },
  created() {
    // 获取所有的城市
    getSelectAreaTree('00', areaRes => {
      this.allCityOption = [...areaRes.data]
      getSubscribeDetail(this.$route.query.projectId, res => {
        this.detailsObj = res.data
        this.detailsObj = res.data
        // 认购流程
        this.detailsObj.lastPayTime = this.detailsObj.lastPayTime && this.detailsObj.lastPayTime.slice(0, -3)
        this.detailsObj.enterTime = this.detailsObj.enterTime && this.detailsObj.enterTime.slice(0, -3)
        this.detailsObj.completeOrCancelOrderTime = this.detailsObj.completeOrCancelOrderTime && this.detailsObj.completeOrCancelOrderTime.slice(0, -3)
        this.detailsObj.createTime = this.detailsObj.createTime && this.detailsObj.createTime.slice(0, -3)

        this.detailsObj.enterEndDate = `${res.data.enterEndDate} 23:59`
        this.detailsObj.balancePaymentEndDate = `${res.data.balancePaymentEndDate} 23:59`
        this.detailsObj.projectEndDate = `${res.data.projectEndDate} 23:59`
        this.detailsObj.depositValue = res.data.depositValue + (res.data.depositType === 1 ? '%比例' : '元定额')
        this.detailsObj.deliveryPeriod = `合同签订后${res.data.deliveryPeriod}天日提货，超期后需自行线下支付仓储费`
        this.listData.push({
          threeName: `${res.data.categoryName}/${res.data.varietyName}/${res.data.goodsName}`,
          goodsName: res.data.goodsName,
          materials: res.data.materials,
          productions: res.data.productions,
          categoryName: res.data.categoryName,
          goodsMaterials: res.data.goodsMaterials,
          goodsSpecs: res.data.goodsSpecs,
          goodsProduct: res.data.goodsProduct,
          skuId: res.data.skuId,
          goodsWeights: res.data.goodsWeights,
          centralizedPurchaseTotalQuantity: res.data.centralizedPurchaseTotalQuantity,
          minSubscribeQuantity: res.data.minSubscribeQuantity,
          meterageUnit: res.data.meterageUnit,
          centralizedPurchasePrice: '￥' + thousandsFractionsSmaller(res.data.centralizedPurchasePrice),
          currentMarketPrice: '￥' + thousandsFractionsSmaller(res.data.currentMarketPrice)
        })
        this.allCityOption.map(provice => {
          if (provice.code === res.data.placeProvince) {
            this.detailsObj.address = `${provice.name}`
            provice.children && provice.children.map(city => {
              if (city.code === res.data.placeCity) {
                this.detailsObj.address += `/${city.name}/`
                city.children && city.children.map(area => {
                  if (area.code === res.data.placeTown) {
                    this.detailsObj.address += `${area.name}` + (!res.data.placeDetailAddr ? '' : `/${res.data.placeDetailAddr}`)
                  }
                })
              }
            })
          }
        })
      })
    })
  }
}
</script>
<style lang="scss">
    .collection-detail-wrapper {
        background: transparent;
        .content-title-area {
            padding-left: 0;
        }
        .content-title-area .title span {
            padding: 4px;
            width: 50px;
            background: #ccc;
            color: red;
        }
        .collection-detail {
            background: #fff;
        }
        .statistics {
            display: flex;
            justify-content: space-between;
            &-item {
                border: 1px solid #ccc;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 10px;
                p:first-child {
                    color: blue;
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 5px;
                }
            }
        }
        .subscribe-step-wrapper {
            background: #fff;
            margin: 16px 0;
            .title {
                padding-left: 24px;
            }
            .subscribe-step {
                margin: 40px 24px 20px;
                display: flex;
                &-top {
                    display: flex;
                    align-items: center;
                    img:nth-of-type(2) {
                        margin: 0 40px;
                    }
                }
                &-title {
                    font-size: 20px;
                    font-weight: 700;
                    height: 68px;
                    line-height: 68px;
                }
                .subscribe-content {

                    span:nth-of-type(1) {
                        height: 28px;
                        line-height: 28px;
                        width: 120px;
                        display: inline-block;
                        font-size: 14px;
                        color: #999999;
                    }
                    .amount {
                        color: #F3831B;
                        font-weight: 500;
                    }
                    .order {
                        color: #0172EB;
                    }
                }
            }
            // .subscribe-content {
            //     display: flex;
            //     justify-content: space-between;
            //     margin: 0 40px;
            //     &-item {
            //         margin-bottom: 40px;
            //         p {
            //             height: 30px;
            //             line-height: 30px;
            //         }
            //     }
            // }
        }

    }
</style>
